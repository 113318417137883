@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bad+Script&display=swap");
body {
  scroll-margin-top: 60px;
  scroll-snap-stop: 60px;
  margin: 0;
  font-family: "Lato", sans-serif;
  background-color: #ebebeb;
  /* background-image: url("https://img.freepik.com/premium-vector/red-chili-pepper-seamless-pattern_23965-206.jpg?w=2000"); */
}
.all {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .all {
    width: 80%;
  }
}
:root {
  width: 100vw;
  overflow-x: hidden;
  --main-border: 15px;
  --bg-color: #770000;
  --primary-color: #b30000;
  --secondary-color: #ff8080;
  --third-color: #ffbfbf;
  --title-color: #ffffff;
  --sub-color: #616161;
}
.regular-shadow {
  -webkit-box-shadow: 0px 2px 10px -2px #00000056;
  box-shadow: 0px 2px 10px -2px #00000056;
}
.box-shadow {
  -webkit-box-shadow: 0px 0px 10px 0px #00000056;
  box-shadow: 0px 0px 10px 0px #00000056;
}
