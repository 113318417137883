.space {
  height: 200px !important;
}
.main-container {
  margin-top: 70px;
  width: 100%;
}
@media screen and (min-width: 580px) {
  .main-container {
    width: 80%;
  }
}
@media screen and (min-width: 980px) {
  .main-container {
    width: 49%;
  }
}
.navbar-row {
  height: 55px;
  border-radius: 1000px;
  background-color: var(--bg-color);
  margin: var(--main-border);
  z-index: 1000;
}
@media screen and (min-width: 980px) {
  .navbar-row {
    margin: auto;
    width: 49%;
    height: 50px;
  }
}
.navbar-row .col-2 {
  padding: 0 !important;
}

.navbar-row .button {
  border: 1px solid var(--bg-color);

  height: 55px;
  width: 55px;
  padding: 0 !important;
  font-size: 1.8rem;
  border-radius: 1000px;
  font-size: 1.8rem;
  background-color: white;
  padding: 5px 1px;
}
.navbar-row .button img {
  width: 55px;
  border-radius: 1000px;
}
.navbar-title {
  height: 55px;
  border-radius: 100px;
  margin: auto;
  padding-top: 5px;

  font-family: "Bad Script", cursive;
  font-size: 2rem;
  background-color: var(--primary-color);
  color: var(--title-color);
}
@media screen and (min-width: 980px) {
  .navbar-title {
    height: 50px;
    font-size: 1.8rem;
  }
  .navbar-row .button {
    height: 50px;
    width: 50px;
  }
}
.offcanvas-full {
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}
.offcanvas-overlay {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.349);
  height: 100vh;
}

.offcanvas-left {
  right: 0;
  width: 60%;
  z-index: 1000;
  background-color: white;
  height: 100vh;
}
.offcanvas-left dl {
  padding: 0px 15px;
}
.offcanvas-left dt {
  margin-bottom: 9px;
  margin-left: 28px;
  font-size: 1.5rem;
}
.offcanvas-left dd {
  font-size: 1.2rem;
}
.offcanvas-left dd a {
  text-decoration: none;
  color: black;
}
.offcanvas-left dd svg {
  margin-right: 10px;
  width: 20px;
}

:is(.offcanvas-full).showOC {
  animation: showOC 0.3s forwards;
}

:is(.offcanvas-full).hideOC {
  animation: hideOC 0.3s forwards;
}
@keyframes showOC {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes hideOC {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

.offcanvas-left button {
  height: 55px;
  width: 55px;
  padding: 0 !important;
  margin: 15px 30px 0px 0px;
  font-size: 1.8rem;
  background-color: white;
  border-radius: 1000px;
}
/* hero */
.hero-container {
  background-color: rgba(0, 0, 0, 0.418);
  margin-top: 10px;
  justify-content: center;
  border-radius: 20px;
  margin-right: 25px;
  margin-left: 25px;
}
.hero-container h1 {
  padding: 5px 10px;
  font-weight: 800;
  font-size: 3rem;
  position: absolute;
  top: 110px;
  text-align: center;
  color: var(--title-color);
}
.hero-container img {
  z-index: -1;
  border-radius: 20px;
  width: 100%;
  height: 200px;
  aspect-ratio: 16/9;
  object-fit: cover;
}
/* Category row list */
.top-category-container {
  top: -500px;
  z-index: 200;
  border-radius: 20px 20px 0px 0px;
  background-color: white;
  margin-top: -50px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 3px 0px;
  padding-bottom: 10px;
}
.top-category-container h2 {
  font-weight: 600;
}
.category-list-row {
  overflow-y: hidden;
  height: 70px;
  /* right: 0;
  left: 0;
  top: 57px; */
  padding: 10px 0px;
  font-size: 1rem;
  font-weight: 300;
  display: flex;
  overflow-x: auto; /* Aggiunge la barra di scorrimento orizzontale */
  white-space: nowrap; /* Impedisce il ritorno a capo degli elementi */
}
.category-button {
  border-radius: 100px;
  display: block;
  text-decoration: none;
  padding: 8px 5px;
  margin: 0px 5px;
  background-color: var(--bg-color);
  color: var(--title-color);
}

.menu-main-container {
  background-color: rgba(255, 255, 255, 0.877);
  border-radius: 20px;
  margin-top: -30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
  margin-left: var(--main-border);
  margin-right: var(--main-border);
}
.title-category-row {
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: var(--bg-color);
  padding: 5px 10px;
  color: white;
}
.menu-main-container h3 {
  letter-spacing: 4px;
  font-size: 1.5rem;
  padding-right: 10px;
  margin-bottom: 0px;
  width: fit-content;
}
.menu-main-container .divider {
  padding: 0px 10px;
  width: 100%;
  border: 1px solid rgb(255, 255, 255);
}
/* menu Item */
.menu-item-conatiner {
  cursor: pointer;
  margin-bottom: 10px;
  height: 100px;
  background-color: rgb(252, 239, 223);
  border-radius: 20px;
}

.menu-item-image {
  background-color: white;
  border-radius: 20px 0px 0px 20px;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.menu-item-text-container {
  width: 100%;
  padding: 3px 5px;
}
.menu-item-text-container h4 {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0px;
}
.menu-item-text-container p {
  height: 45px;
  overflow: hidden;
  margin-bottom: 0px;
  color: var(--sub-color);
  font-size: 0.9rem;
}
.menu-item-conatiner span {
  display: block;
  margin-right: 5px;
}
.menu-item-conatiner span svg {
  color: rgb(255, 174, 0);
}

.menu-item-conatiner span:nth-child(2) {
  margin-right: 5px;
}
.menu-item-text-container p {
}
.arrow-to-top {
  width: 100%;
  position: fixed;
  z-index: 2000;
  bottom: 30px;
}
.arrow-to-top a {
  display: block;
  width: 55px;
  height: 55px;
  text-decoration: none;
  padding: 0px 6px 3px 6px;
  border-radius: 100px;
  background-color: var(--bg-color);
  color: white;
  font-weight: bold;
  font-size: 2rem;
}
.arrow-to-top.show {
  animation: show 0.3s forwards;
}
@keyframes show {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.arrow-to-top.hide {
  animation: hide 0.3s forwards;
}
@keyframes hide {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.product-modal.showPM {
  z-index: 100000000;
  display: block;
  animation: showPM 0.4s forwards;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.808);
  transition: display 0.4s;
}
.product-modal.hidePM {
  display: none;
  animation: hidePM 0.4s forwards;
}
@keyframes showPM {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes hidePM {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
.product-modal {
  padding: 15px;
}
.modal-heder button {
  height: 41px;
  width: 41px;
  padding: 0 !important;
  font-size: 1.8rem;
  background-color: white;
  border-radius: 1000px;
}
.modal-body {
  margin-top: 70px;
  /* border-radius: 20px;
  padding: 10px;
  background-color: white; */
}

.modal-body .image-container {
  z-index: 400;
  z-index: -1;
  border-radius: 20px;
}
.modal-body img {
  max-height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.product-info-container {
  margin-top: -50px;

  padding: 10px 20px;
  border-radius: 20px;
  background-color: white;
}
.product-info-container h3 {
  font-size: 2rem;
}
.product-info-container p {
  color: var(--sub-color);
  font-size: 1.2rem;
}
.product-info-container p span {
  font-size: 1.4rem;
}
.homepage {
  padding-bottom: 5px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.966);
  margin: var(--main-border);
}
.home-hero .menu {
}
.home-hero h5 {
  padding: 15px 0px;
  font-family: "Source Code Pro", monospace;
  margin: 0;
  font-weight: 600;
  font-size: 1em;
}
.home-hero h5 span {
  margin: 0px 5px;
}

.carousel-container img {
  object-fit: cover;
  width: 100%;
}
.carousel-container span {
  border-radius: 20px;
  font-size: 1.4rem;
  border: white 3px solid;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.562);
  position: absolute;
  top: 40%;
  bottom: 40;
  color: white;
}

.carousel-container {
  max-width: 407px;
  margin: 0px 15px;
}
.homepage a {
  font-size: 1.2rem;
  text-decoration: none;
  margin: 15px;

  border-radius: 20px;
  letter-spacing: 1px;
  padding: 0px 15px;
  color: white;
  background-color: var(--primary-color);
  border-bottom: 3px solid var(--bg-color);
  /* background-color: var(--bg-color); */
}

.homepage section {
  background-color: white;
  border-radius: 20px;
  margin: 0px 15px;
  margin-bottom: 20px;
}
.homepage section p {
  margin: 0;
  padding: 15px;
}
.homepage section p:nth-last-child() {
}
.homepage hr {
  margin: 0;
}
.btn-menu-cont {
  margin: 0px 15px;
}
.homepage button {
  border-radius: 100px;
  text-decoration: none;
  padding: 8px 15px;
  border: 2px solid var(--bg-color);
  background-color: rgba(243, 3, 3, 0.226);
  color: black;
}
footer {
  border-radius: 20px 20px 0px 0px;
  /* position: fixed;
  bottom: 0; */

  background-color: var(--bg-color);
}

.contact-row svg {
  margin: 0px 18px;
}

footer :is(.credit, a) {
  color: white;
}
.id {
  padding-top: 60px;
}
.id:nth-child(1) {
  padding-top: 0px;
}
/* challenge-page  */
.challenge-page {
  height: 100vh;
}
.challenge-button {
  font-size: 2rem;
  border-radius: 20px;
  padding: 8px 10px;
  background-color: var(--bg-color) !important;
  color: var(--title-color) !important;
  box-shadow: 2px 2px 0px 2px rgb(187, 0, 0);
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -1rem, 0);
  }
}
.bouncing-loader {
  display: flex;
  justify-content: center;
}
.bouncing-loader > div {
  width: 1rem;
  height: 1rem;
  margin: 3rem 0.2rem;
  background: var(--bg-color);
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
.box-challenge {
  height: 200px;
  margin: var(--main-border);
  padding: var(--main-border);
  border-radius: 20px;
}
.box-challenge p {
  font-size: 1.4rem;
  margin: 0;
}
